import React from "react"
import DrupalImage from "./DrupalImage";

export default ({ data }) => {
    const {
        field_title_component_prod_app	 = [],
        field_text_image_comp_prod_app = [],
        field_text_comp_prod_app = [],
        field_sub_text_comp_prod_app = [],
        field_link_component_prod_app = [],
        field_img_component_prod_app = [],
    } = data || {};

    const [title] = field_title_component_prod_app;
    const [text] = field_text_comp_prod_app;
    const [text_i ] = field_text_image_comp_prod_app;
    const [sub_text] = field_sub_text_comp_prod_app;
    const uri = field_link_component_prod_app[0]?.uri;
    const [ imgData ] = field_img_component_prod_app;
    console.log(text_i)

    return (
        <section className="container product-aprroved">
            <div className="row">
                <div className="col-md-6 product-aprroved-text">
                    {title !== undefined &&
                        <a href={uri}><h3>{title.value}</h3></a>
                    }
                    {text !== undefined &&
                        <div dangerouslySetInnerHTML={{
                            __html: text.value
                        }}></div>
                    }
                    {sub_text !== undefined &&
                        <div className="product-sub-text" dangerouslySetInnerHTML={{
                        __html: sub_text.value
                    }}></div>
                    }
                </div>
                <div className="col-md-6 product-aprroved-img">
                    {imgData !== undefined &&
                        <a href={uri}> <DrupalImage { ...imgData } style={{ height: 'auto' }}/></a>
                    }
                    {text_i !== undefined &&
                    <div dangerouslySetInnerHTML={{
                        __html: text_i.value
                    }}></div>
                    }
                </div>


            </div>
        </section>
    )
}
