import React, {useState} from "react";
import axios from 'axios';
import { Button, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import {graphql, useStaticQuery} from "gatsby";
import * as url from "url";
const { GATSBY_API_URL = 'http://localhost' } = process.env;

//const {BASE_URL = window.location.origin} = process.env;
// const { GATSBY_API_ENV = 'default' } = process.env;
export default () => {
    const [ msg, setMsg ] = useState(null);
    const { register, handleSubmit, reset, errors, setError, watch } = useForm();

    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            envName
          }
        }
      }
    `
    )

    const envName = site.siteMetadata?.envName;

    const countries = [
        '',
        'United States',
        'China',
        'France',
        'Germany',
        'United Kingdom',
        'Spain',
        'Australia',
        'Argentina',
        'TURKEY',
        'Poland',
        'India',
        'South Africa',
        'Taiwan',
        'Canada',
        'Netherlands',
        'South Korea',
        'Czech',
        'Sweden',
        'Italy',
        'Ireland',
        'Romania',
        'Japan',
        'Israel',
        'Belgium',
        'Mexico',
        'Hungary',
    ];
    const ukCounties = ["", "Aberdeenshire", "Angus", "Argyll & Bute", "Ayrshire", "Banffshire", "Bedfordshire", "Berkshire", "Berwickshire", "Blaenau Gwent", "Borders", "Bridgend", "Buckinghamshire", "Caerphilly", "Caithness", "Cambridgeshire", "Cardiff", "Carmarthenshire", "Ceredigion", "Cheshire", "Clackmannanshire", "Cleveland", "Conwy", "Cornwall", "County Durham", "Cumbria", "Denbighshire", "Derbyshire", "Devon", "Dorset", "Dumfries & Galloway", "Dunbartonshire", "East Ayrshire", "East Dunbartonshire", "East Lothian", "East Renfrewshire", "East Riding of Yorkshire", "East Sussex", "Essex", "Fife", "Flintshire", "Gloucestershire", "Greater London", "Greater Manchester", "Gwynedd", "Hampshire", "Herefordshire", "Hertfordshire", "Highland", "Inverclyde", "Isle of Anglesey", "Isle of Wight", "Isles of Scilly", "Kent", "Kincardineshire", "Lanarkshire", "Lancashire", "Leicestershire", "Lincolnshire", "Merseyside", "Merthyr Tydfil", "Midlothian", "Monmouthshire", "Moray", "Neath Port Talbot", "Newport", "Norfolk", "North Ayrshire", "North Lanarkshire", "North Yorkshire", "Northamptonshire", "Northumberland", "Nottinghamshire", "Orkney", "Oxfordshire", "Pembrokeshire", "Perth & Kinross", "Powys", "Renfrewshire", "Rhondda Cynon Taff", "Rutland", "Shetland", "Shropshire", "Somerset", "South Ayrshire", "South Lanarkshire", "South Yorkshire", "Staffordshire", "Stirlingshire", "Suffolk", "Surrey", "Swansea", "Torfaen", "Tyne & Wear", "Vale of Glamorgan", "Warwickshire", "West Dunbartonshire", "West Lothian", "West Midlands", "West Sussex", "West Yorkshire", "Western Isles", "Wiltshire", "Worcestershire", "Wrexham"];

    const onSubmit = data => {
        const webform_id = "inquiry_webform";
        const url = typeof window !== 'undefined' ? window.location.href : '';

        //const query = '?name='+data.name + '&email=' + data.email + '&country=' + data.country + '&phone=' + data.phone + '&company=' + data.company + '&message=' + data.message + '&region=' + data.region
        const query = [`name`, 'email', 'country', 'phone', 'company', 'message', 'region']
          .map((key) => `${key}=${encodeURIComponent(data[key])}`)
          .join('&');
        setMsg(null);
        axios.post(`${GATSBY_API_URL}/webform_rest/submit`, { ...data, webform_id })
            .then(() => {
                const BASE_URL = (typeof window !== 'undefined') ? window.location.origin : '';
                // setMsg("Successfully sent.");
                reset({});
                document.location = BASE_URL+'/thank-you/?' + query;

            })
            .catch(({ response: { data:e } }) => {
                reset(data);
                if (e && e.message && e.error) {
                    for (let key in e.error) {
                        setError(key, { type: 'validate', message: e.error[key] });
                    }
                }
            });
    };
    const fields = [
        {
            key: "name",
            label: "Name",
            required: true,
            type: "text",
        },
        {
            key: "env",
            label: "env",
            required: true,
            type: "text",
        },
        {
            key: "email",
            label: "Email",
            required: true,
            type: "text",
        },
        {
            key: "country",
            label: "Country",
            required: true,
            type: "select",
            options: countries,
        },
        {
            key: "region",
            label: "County",
            required: true,
            type: "select",
            options: ukCounties,
            condition: (watch('country') === 'United Kingdom'),
        },
        {
            key: "phone",
            label: "Phone",
            required: false,
            type: "text",
        },
        {
            key: "company",
            label: "Company",
            required: false,
            type: "text",
        },
        {
            key: "message",
            label: "Message",
            required: true,
            type: "textarea",
        },
    ];
    const RawHTML = ({children, className = ""}) =>
        <div
            className={className}
            dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />')}}
        />;
    const FormComponent = (props) => {
        switch (props.type) {
            case 'select':
                return (
                  [
                    <label>{props.title}</label>,
                    <select { ...props } reg="" ref={props.reg} defaultValue={watch(props.id)} options={null}>{props.options.map(c => <option value={c}>{c}</option>)}</select>,
                  ]
                );
            case "textarea":
                // return <textarea { ...props } reg="" ref={props.reg} />
                return (
                    // <div className={props.nameclass}>
                        [<label>{props.title}</label>,
                        <textarea { ...props } reg="" ref={props.reg} />]
                    // </div>
                );
            default:
                // return <input { ...props } reg="" ref={props.reg} />
                return (
                   // <div className={props.nameclass}>
                        [<label>{props.title}</label>,
                        <input { ...props } reg="" ref={props.reg} value={props.val} />]
                    //</div>
                );
        }
    };
    return (
        <section className="contact-form" style={{backgroundColor: "#D6ECFC" }}>
            <div className="container">
                <div className="row">

                    <div className="col-md-9">
                        {msg && <Alert color="success">{msg}</Alert>}
                        <h2>Inquiry form</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {
                                fields.map(({ key, label, required, type, options = null, condition}) => (typeof condition === "boolean" && !condition) ? null : (
                                    <div key={key} className= {required ? "contact-el required" : "contact-el"} id={key === 'env' ? 'env': null}>
                                        <FormComponent
                                            htmlFor={key}
                                            id={key}
                                            title={label}
                                            required={required}
                                            type={type}
                                            name={key}
                                            reg={register({required})}
                                            val={key === 'env' ? envName : null}
                                            options={options}
                                        />
                                        {
                                            errors[key] && <span
                                                className="text-danger small"><RawHTML>{errors[key].message || 'This field is required'}</RawHTML></span>
                                        }
                                    </div>
                                ))
                            }
                            <div className="contact-el submit">
                                <input className="submit-btn" type="submit" value="Submit"/>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </section>
    );
};
