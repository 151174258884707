import React from "react";
import Select, { components } from "react-select";
import { navigate } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

import Home from './home';
import Markets from './management-page';
import Products from './products-page';
import Resorces from './document-page';
import SingleNews from './news-single-page';
import Vehicles from './vehicles-page';
import Contact from './contact-page';
import Basic from './basic-page';
import Faq from './faq-page';
import OneTrust from "../components/OneTrust";

const languages = {
  'en': 'English',
  'fr': 'Français',
  'de': 'Deutsch',
  'es': 'Español',
};

export default ({ pageContext: { node, title, translations, lang, urlPath } }) => {
  const meta = [];
  {node.metatag_arr !== undefined &&
      Object.entries(node.metatag_arr).forEach(([key, value]) => {
       meta.push({name: key, content: value});
  })}

    const options = translations.map((item, i) => ({
        value: item,
        label: languages[item],
        icon: item,
    }));

    const { Option, SingleValue } = components;

    const IconOption = (props) => (
        <Option {...props}>
            <img
                src={require('../images/icons/' + props.data.icon + '.png')}
                style={{ height: 20, width:28, margin: 2 }}
                alt={props.data.label}
            />
            {" "+props.data.label}
        </Option> );

    const ValueOption = (props) => (
        <SingleValue {...props}>
            <img
                src={require('../images/icons/' + props.data.icon + '.png')}
                style={{ height: 20, width:28, margin: 2 }}
                alt={props.data.label}
            />
            {" "+props.data.label}
        </SingleValue> );


  const onSwitchLang = ({value}) => {
    const langPath = value === 'en' ? '' : '/' + value;
    navigate(urlPath + langPath);
  };

  return (
    <Layout>
      <OneTrust/>
      <SEO title={title} meta={meta}/>
      {
        (options.length > 1 && urlPath !== '/') && (
          <div className="container position-relative">
            <div className="lang-switcher col-lg-2 col-5">
              <Select
                classNamePrefix="obw"
                className={options.value}
                defaultValue={options.value}//options[0]
                value={options.value}
                options={options}
                placeholder={languages[lang]}
                onChange={onSwitchLang}
                isSearchable={false}
                components={{ Option: IconOption}}//need add - SingleValue : ValueOption
              />
            </div>
          </div>
        )
      }
      { node.type[0].target_id === 'homepage' && <Home node={node} /> }
      { node.type[0].target_id === 'markets' && <Markets node={node} /> }
      { node.type[0].target_id === 'products' && <Products node={node} /> }
      { node.type[0].target_id === 'resources' && <Resorces node={node} /> }
      { node.type[0].target_id === 'news' && <SingleNews node={node} /> }
      { node.type[0].target_id === 'vehicles' && <Vehicles node={node} /> }
      { node.type[0].target_id === 'contact' && <Contact node={node} /> }
      { node.type[0].target_id === 'basic_page' && <Basic node={node} /> }
      { node.type[0].target_id === 'faq' && <Faq node={node} /> }
    </Layout>
  );
};
