import React from "react";

import OnboardSection from "../components/OnboardSection";
import VpgOnboard from "../components/VpgOnboard";
import OnboardSectionItems from "./OnboardSectionItems";
import FourBlock from "./FourBlock";
import Banner from "./Banner";
import Slider from "./Slider";
import EventNews from "./EventNews";
import BannerImg from "./BannerImg";
import OverloadProtection from "./OverloadProtection";
import ProductsAndSystems from "./ProductsAndSystems";
import ManagementOnboard from "./ManagementOnboard";
import ProductApproved from "./ProductApproved";
import ProductContactBlock from "./ProductContactBlock";
import ProductHighlihts from "./ProductHighlihts";
import SelectForm from "./SelectForm";
import VehiclesItems from "./VehiclesItems";
import VehiclesOnboard from "./VehiclesOnboard";
import TitleDivider from "./TitleDivider";
import SalesOffices from "./SalesOffices";
import Representatives from "./Representatives";
import FaqForm from "./FaqForm";
import DrupalForm from "./DrupalForm";
import NewsBlock from "./NewsBlock";

const map = {
  'tiltle_component': OnboardSection,
  'vpg_onboard_component': VpgOnboard,
  'onboard_section_items_component': OnboardSectionItems,
   'banner_slider_component': Banner,
  'four_block_component': FourBlock,
  'slider_component': Slider,
  'events_component': EventNews,
  'banner_img_component': BannerImg,
  'overload_protection_component': OverloadProtection,
  'products_ans_systems_component': ProductsAndSystems,
  'manegement_onboard_component': ManagementOnboard,
  'product_approved_component': ProductApproved,
  'product_contact_component': ProductContactBlock,
  'columns_component': ProductHighlihts,
  'file_table_component': SelectForm,
  'sub_categories_rows_component': VehiclesItems,
  'two_colums_component': VehiclesOnboard,
  'tiltle_divider_component': TitleDivider,
  'sales_offices_component': SalesOffices,
  'representatives_component': Representatives,
  'faq_component': FaqForm,
  'contact_us_webform_component': DrupalForm,
  'news_views_component': NewsBlock,
};

export default ({ data }) => {
  return (data) ? data.map((item, i) => {
    if (!item || !item.type) return null;
    const { type: [{ target_id }] } = item;
    const Component = map[target_id];
    return Component ? (
      <div key={i}>
        <Component data={item} />
      </div>
    ) : null

  }) : null;
};
